import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosHelper";
import "../Auth.css";

function SignUp() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [isOtpPage, setisOtpPage] = useState(false);
  const [otp, setOtp] = useState(null);

  let {
    enteredInput: enteredEmail,
    inputValid: emailInputIsValid,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
    reset: emailInputReset,
  } = useInput("", (value) => value !== "");

  let {
    enteredInput: enteredPassword,
    inputValid: passwordInputIsValid,
    inputInvalid: passwordInputIsInvalid,
    blurHandler: passwordInputBlurHandler,
    changeHandler: passwordInputChangeHandler,
    reset: passwordInputReset,
  } = useInput("", (value) => value !== "" && value.length >= 8);

  let {
    enteredInput: enteredConfirmPassword,
    inputValid: confirmPasswordInputIsValid,
    inputInvalid: confirmPasswordInputIsInvalid,
    blurHandler: confirmPasswordInputBlurHandler,
    changeHandler: confirmPasswordInputChangeHandler,
    reset: confirmPasswordInputReset,
  } = useInput("", (value) => value === enteredPassword && value.length >= 8);

  let {
    enteredInput: enteredName,
    inputValid: nameInputIsValid,
    inputInvalid: nameInputIsInvalid,
    blurHandler: nameInputBlurHandler,
    changeHandler: nameInputChangeHandler,
    reset: nameInputReset,
  } = useInput("", (value) => value !== "");

  const signUpHandler = async (e) => {
    e.preventDefault();

    if (isOtpPage) {
      const response = await axiosInstance.post("user/verifyViaOTP", {
        email: enteredEmail,
        OTP: otp,
      });

      if (response.data.status === "success") {
        toast.success("Successfully logged in!");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        authCtx.login(response.data.data.user);
        navigate("/admin/dashboard");
      }
    } else {
      nameInputBlurHandler();
      emailInputBlurHandler();
      passwordInputBlurHandler();
      confirmPasswordInputBlurHandler();

      if (
        (!enteredName && !enteredEmail) ||
        !(enteredPassword.length >= 8) ||
        enteredPassword !== enteredConfirmPassword
      ) {
        return;
      }

      const response = await axiosInstance.post("user/signup", {
        email: enteredEmail,
        password: enteredPassword,
        passwordConfirm: enteredConfirmPassword,
        name: enteredName,
      });

      if (response.data.status === "success") {
        toast.success("OTP sent Successfully");
        setisOtpPage(true);
      }
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <div id="app">
      <div className="container">
        <header>
          <div className={"header-headings"}>
            <span>
              {!isOtpPage
                ? "Create new account"
                : "Enter your verification code"}
            </span>
          </div>
        </header>

        <form className="account-form" onSubmit={signUpHandler}>
          {!isOtpPage ? (
            <div className={"account-form-fields"}>
              <input
                id="name"
                name="name"
                type="name"
                placeholder="Name"
                value={enteredName}
                onChange={nameInputChangeHandler}
                onBlur={nameInputBlurHandler}
              />
              {nameInputIsInvalid && (
                <p className="error-text">Please enter valid name address!</p>
              )}
              <input
                id="email"
                name="email"
                type="email"
                placeholder="E-mail"
                value={enteredEmail}
                onChange={emailInputChangeHandler}
                onBlur={emailInputBlurHandler}
              />
              {emailInputIsInvalid && (
                <p className="error-text">Please enter valid email address!</p>
              )}
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                value={enteredPassword}
                onChange={passwordInputChangeHandler}
                onBlur={passwordInputBlurHandler}
              />
              {passwordInputIsInvalid && (
                <p className="error-text">Please enter atleast 8 character!</p>
              )}
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm password"
                value={enteredConfirmPassword}
                onChange={confirmPasswordInputChangeHandler}
                onBlur={confirmPasswordInputBlurHandler}
              />
              {confirmPasswordInputIsInvalid && (
                <p className="error-text">Password should be match!</p>
              )}
            </div>
          ) : (
            <OtpInput
              className="otpInput"
              value={otp}
              onChange={handleChange}
              numInputs={6}
              isInputNum
            />
          )}
          <button className="btn-submit-form" type="submit">
            {!isOtpPage ? "Sign Up" : "Verify OTP"}
          </button>
        </form>
        <div className="btn-submit-form" style={{ marginTop: "100px" }}>
          <Link to="/login">Already have an account</Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
