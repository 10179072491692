import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosHelper";
import "../Auth.css";

function ForgotPassword() {
	const navigate = useNavigate();

	let {
		enteredInput: enteredEmail,
		inputValid: emailInputIsValid,
		inputInvalid: emailInputIsInvalid,
		blurHandler: emailInputBlurHandler,
		changeHandler: emailInputChangeHandler,
		reset: emailInputReset,
	} = useInput("", (value) => value !== "");

	const forgotPasswordHandler = async (e) => {
		e.preventDefault();

		emailInputBlurHandler();

		if (!enteredEmail) {
			return;
		}

		const response = await axiosInstance.post("auth/forget-password", {
			email_id: enteredEmail,
		});

		if (response.data.message === "success") {
			// setNewPasswordPage(true);
			toast.success("Password sent to your email address!");
			navigate("/");
		}
	};

	return (
		<div id="app">
			<div className="container">
				<header>
					<div className={"header-headings"}>
						<span>Enter email to reset your password</span>
					</div>
				</header>

				<form className="account-form" onSubmit={forgotPasswordHandler}>
					<div className={"account-form-fields"}>
						<input
							id="email"
							name="email"
							type="email"
							placeholder="E-mail"
							value={enteredEmail}
							onChange={emailInputChangeHandler}
							onBlur={emailInputBlurHandler}
						/>
						{emailInputIsInvalid && (
							<p className="error-text">Please enter valid email address!</p>
						)}
					</div>
					<button className="btn-submit-form" type="submit">
						Sign Up
					</button>
				</form>

				{/* <div className="btn-submit-form" style={{ marginTop: "100px" }}>
          <Link to="/signUp">Create new account</Link>
        </div> */}
			</div>
		</div>
	);
}

export default ForgotPassword;
