import {
	Button,
	Card,
	Collapse,
	Container,
	IconButton,
	MenuItem,
	Paper,
	Popover,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/AxiosHelper";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { filter } from "lodash";
import Iconify from "../../components/Iconify";
import UserListHead from "../../components/UserListHead";
import ScrollBar from "../../components/ScrollBar/ScrollBar";
import Label from "../../components/label/Label";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: "name", label: "Name", alignRight: false },
	{ id: "parentId", label: "Has Parent", alignRight: false },
	{ id: "children", label: "Has Children", alignRight: false },
	{ id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(
			array,
			(_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
		);
	}
	return stabilizedThis.map((el) => el[0]);
}

function Category() {
	const navigate = useNavigate();
	const [openPopUp, setOpenPopUp] = useState(null);
	const [open, setOpen] = React.useState(false);

	const [page, setPage] = useState(0);

	const [order, setOrder] = useState("asc");

	const [selected, setSelected] = useState([]);

	const [orderBy, setOrderBy] = useState("name");

	const [filterName, setFilterName] = useState("");

	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [categories, setCategories] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(true);

	const handleOpenMenu = (event, id, status) => {
		setOpenPopUp(event.currentTarget);
		setSelectedId(id);
		setSelectedStatus(status);
	};

	const handleCloseMenu = () => {
		setOpenPopUp(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = categories.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

	const fetchCategoriesTreeWise = async () => {
		const response = await axiosInstance.get("category/getAllCategories");

		if (response.data.status === "success") {
			setCategories(response.data.data.data);
		}
	};

	useEffect(() => {
		fetchCategoriesTreeWise();
	}, []);

	const renderCategory = (categories, parentActive = true) => {
		return (
			<Table>
				<UserListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={categories.length}
					numSelected={selected.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{categories
						?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						?.map((row) => {
							const { _id, name, status, parentId, children, avatarUrl } = row;
							const selectedUser = selected.indexOf(name) !== -1;

							return (
								<Row
									key={_id}
									row={row}
									handleOpenMenu={handleOpenMenu}
									renderCategory={renderCategory}
									parentActive={parentActive}
								/>
							);
						})}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
								<Paper
									sx={{
										textAlign: "center",
									}}
								>
									<Typography variant="h6" paragraph>
										Not found
									</Typography>

									<Typography variant="body2">
										No results found for &nbsp;
										<strong>&quot;{filterName}&quot;</strong>.
										<br /> Try checking for typos or using complete words.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
		);
	};

	const isNotFound = !categories.length && !!filterName;

	const changeCategoryStatus = async (id) => {
		const response = await axiosInstance.delete(`category/${id}`);
		handleCloseMenu();
		if (response.data.status === "success") {
			toast.success("Category status changed!");
			fetchCategoriesTreeWise();
		}
	};

	return (
		<>
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Category
					</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:plus-fill" />}
						onClick={() => {
							navigate(`/admin/category/add`);
						}}
					>
						New Category
					</Button>
				</Stack>

				<Card>
					{/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

					<ScrollBar>
						<TableContainer sx={{ minWidth: 800 }}>
							{renderCategory(categories)}
						</TableContainer>
					</ScrollBar>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={categories.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>

			<Popover
				open={Boolean(openPopUp)}
				anchorEl={openPopUp}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						"& .MuiMenuItem-root": {
							px: 1,
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<MenuItem
					onClick={() => {
						navigate(`/admin/category/edit/${selectedId}`);
					}}
				>
					<Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
					Edit
				</MenuItem>

				<MenuItem
					sx={{ color: "error.main" }}
					onClick={() => {
						if (
							window.confirm(
								`Are you sure you want to ${
									selectedStatus ? "Inactive" : "Active"
								}?`
							)
						) {
							changeCategoryStatus(selectedId);
						}
					}}
				>
					<Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
					{selectedStatus ? "Inactive" : "Active"}
				</MenuItem>
			</Popover>
		</>
	);
}

export default Category;  

function Row(props) {
	const { row, handleOpenMenu, renderCategory, parentActive } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<TableRow
				hover
				key={row._id}
				tabIndex={-1}
				sx={{ background: row.status === false ? "#e9c7c7" : "" }}
			>
				<TableCell>
					<IconButton size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>

				<TableCell component="th" scope="row" padding="none">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.name}
						</Typography>
					</Stack>
				</TableCell>

				<TableCell align="left">{row.parentId ? "Yes" : "No"}</TableCell>

				<TableCell align="left">{row.children ? "Yes" : "No"}</TableCell>

				<TableCell align="left">
					<Label color={row.status === false ? "error" : "success"}>
						{row.status === false ? "Inactive" : "Active"}
					</Label>
				</TableCell>

				{parentActive && (
					<TableCell align="right">
						<IconButton
							size="large"
							color="inherit"
							onClick={(e) => {
								handleOpenMenu(e, row._id, row.status);
							}}
						>
							<Iconify icon={"eva:more-vertical-fill"} />
						</IconButton>
					</TableCell>
				)}
			</TableRow>
			{row.children && row.children.length > 0 && (
				<TableRow sx={{ background: row.status === false ? "#e9c7c7" : "" }}>
					<TableCell
						style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
						colSpan={12}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{renderCategory(
								row.children,
								parentActive && row.status ? true : false
							)}
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}
