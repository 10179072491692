import {
	DatePicker,
	DesktopDatePicker,
	LocalizationProvider,
	MobileDatePicker,
} from "@mui/x-date-pickers";
import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axiosInstance from "../../../utils/AxiosHelper";

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/useInput";

const ReceiptFilter = ({
	enteredFirstName,
	firstNameInputChangeHandler,
	enteredLastName,
	lastNameInputChangeHandler,
	enteredAmount,
	amountInputChangeHandler,
	enteredApprovalStatus,
	approvalStatusInputChangeHandler,
	enteredCollectedBy,
	collectedByStatusInputChangeHandler,
	enteredPancard,
	pancardStatusInputChangeHandler,
	fetchCategoriesTreeWise,
	setOpenFilter,
}) => {
	// const [value, setValue] = (React.useState < Dayjs) | (null > null);

	const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
	const [options, setOptions] = React.useState([]);

	const handleChange = (newValue) => {
		setValue(newValue);
	};

	const getAllMemberList = async () => {
		const response = await axiosInstance.get("donation/all-member-list");

		console.log("responseresponseresponseresponse", response.data.data.result);

		if (response.data.message === "success") {
			setOptions(response.data.data.result);
		}
	};

	useEffect(() => {
		getAllMemberList();
	}, []);

	return (
		<div style={{ background: "#e5f2ff", padding: "15px" }} sx={{}} id="app">
			<div className={"account-form-fields"}>
				<Grid container spacing={2}>
					{/* <Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">Date</InputLabel>
							<input
								id="name"
								name="first_name"
								type="date"
								placeholder="First Name"
								value={"enteredName"}
								onChange={"nameInputChangeHandler"}
							/>
						</div>
					</Grid> */}
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">
								First Name
							</InputLabel>
							<input
								id="name"
								name="first_name"
								type="text"
								placeholder="First Name"
								value={enteredFirstName}
								onChange={firstNameInputChangeHandler}
							/>
							{/* {nameInputIsInvalid && (
								<p className="error-text">Please enter valid Firts Name !</p>
							)}{" "} */}
						</div>
					</Grid>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">
								Last Name
							</InputLabel>
							<input
								id="last_name"
								name="last_name"
								type="text"
								placeholder="Last Name"
								value={enteredLastName}
								onChange={lastNameInputChangeHandler}
							/>
							{/* {nameInputIsInvalid && (
								<p className="error-text">Please enter valid Firts Name !</p>
							)}{" "} */}
						</div>
					</Grid>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">
								Amount
							</InputLabel>
							<input
								id="amount"
								name="amount"
								type="text"
								placeholder="Amount"
								value={enteredAmount}
								onChange={amountInputChangeHandler}
							/>
							{/* {nameInputIsInvalid && (
								<p className="error-text">Please enter valid Firts Name !</p>
							)}{" "} */}
						</div>
					</Grid>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">
								Approval Status
							</InputLabel>
							<Select
								id="demo-simple-select-disabled"
								value={enteredApprovalStatus}
								label="DonationType"
								onChange={approvalStatusInputChangeHandler}
								defaultValue={1}
								style={{ background: "#fff" }}
							>
								<MenuItem value={1}>Pending</MenuItem>
								<MenuItem value={2}>Approved</MenuItem>
								<MenuItem value={3}>Rejected</MenuItem>
							</Select>
						</div>
					</Grid>
					{/* <Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">
								Donation Received By
							</InputLabel>
							<Select
								id="demo-simple-select-disabled"
								value={enteredCollectedBy}
								label="DonationType"
								onChange={collectedByStatusInputChangeHandler}
								style={{ background: "#fff" }}
							>
								{options?.map((item) => {
									return (
										<MenuItem value={item.user_id}>
											{item.first_name} &nbsp;
											{item.last_name}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					</Grid> */}
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<div className={"account-form-fields"}>
							<InputLabel id="demo-simple-select-helper-label">
								Pan Card Number
							</InputLabel>
							<input
								id="Pan Card"
								name="Pan Card"
								type="text"
								placeholder="Pan Card"
								value={enteredPancard}
								onChange={pancardStatusInputChangeHandler}
							/>
						</div>
					</Grid>
				</Grid>
				<button
					className="btn-submit-form"
					onClick={() => {
						fetchCategoriesTreeWise();
						setOpenFilter(false);
					}}
				>
					Apply
				</button>
			</div>
		</div>
	);
};

export default ReceiptFilter;
