import { sentenceCase } from "change-case";
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Checkbox,
	Collapse,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Popover,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@mui/material";
import "./users.css";
import React, { useEffect, useRef, useState } from "react";
// import UserListToolbar from "../../components/UserListToolbar";
import axiosInstance from "../../../utils/AxiosHelper";
import ReactToPrint from "react-to-print";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";

// import USERLIST from "../../_mock/user";
import { filter } from "lodash";
import Iconify from "../../../components/Iconify";
import UserListHead from "../../../components/UserListHead";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import Label from "../../../components/label/Label";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddUser from "./AddReceipt";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import ReceiptFilter from "./ReceiptFilter";
import ViewSingleReceipt from "./ViewSingleReceipt";
import RejectedReceiptResoan from "./RejectedReceiptResoan";
import useInput from "../../../hooks/useInput";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: "donor_first_name", label: "First Name", alignRight: false },
	{ id: "donor_last_name", label: "Last Name", alignRight: false },
	{ id: "donation_amount", label: "Amount", alignRight: false },
	{ id: "donor_email_id", label: "Email", alignRight: false },
	{ id: "donor_mobile_number", label: "Mobile Number", alignRight: false },
	{ id: "pan", label: "Pan Card", alignRight: false },
	{ id: "is_donation_approved", label: "Status", alignRight: false },
	{ id: "View", label: "", alignRight: false },

	// { id: "address", label: "Has Children", alignRight: false },
	// { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function ReceiptTableMember() {
	const navigate = useNavigate();
	const [openPopUp, setOpenPopUp] = useState(null);
	const [receiptId, setReceiptId] = React.useState(null);
	const [open1, setOpen1] = React.useState(false);

	const [openFilter, setOpenFilter] = React.useState(false);
	const [openView, setOpenView] = React.useState(false);
	const [openReasonModel, setOpenReasonModel] = React.useState(false);
	const userInfo = JSON.parse(localStorage.getItem("user"));

	let {
		enteredInput: enteredFirstName,
		inputValid: firstNameInputIsValid,
		inputInvalid: firstNameInputIsInvalid,
		blurHandler: firstNameInputBlurHandler,
		changeHandler: firstNameInputChangeHandler,
		reset: firstNameInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredLastName,
		inputValid: lastNameInputIsValid,
		inputInvalid: lastNameInputIsInvalid,
		blurHandler: lastNameInputBlurHandler,
		changeHandler: lastNameInputChangeHandler,
		reset: lastNameInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredAmount,
		inputValid: amountInputIsValid,
		inputInvalid: amountInputIsInvalid,
		blurHandler: amountInputBlurHandler,
		changeHandler: amountInputChangeHandler,
		reset: amountInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredApprovalStatus,
		inputValid: approvalStatusInputIsValid,
		inputInvalid: approvalStatusInputIsInvalid,
		blurHandler: approvalStatusInputBlurHandler,
		changeHandler: approvalStatusInputChangeHandler,
		reset: approvalStatusInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredCollectedBy,
		inputValid: collectedByStatusInputIsValid,
		inputInvalid: collectedByStatusInputIsInvalid,
		blurHandler: collectedByStatusInputBlurHandler,
		changeHandler: collectedByStatusInputChangeHandler,
		reset: collectedByStatusInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredPancard,
		inputValid: pancardStatusInputIsValid,
		inputInvalid: pancardStatusInputIsInvalid,
		blurHandler: pancardStatusInputBlurHandler,
		changeHandler: pancardStatusInputChangeHandler,
		reset: pancardStatusInputReset,
	} = useInput("", (value) => value !== "");

	// ReasonModel
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen1(true);
	};

	const handleClose = () => {
		setOpen1(false);
	};

	const handleClickOpenFilter = () => {
		setOpenFilter(true);
	};

	const handleCloseFilter = () => {
		setOpenFilter(false);
	};

	const onViewRecipt = (item) => {
		setReceiptId(item);
		setOpenView(true);
	};

	const onReasonModelOpen = (item) => {
		setReceiptId(item);
		setOpenReasonModel(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleCloseReasonModel = () => {
		setOpenReasonModel(false);
	};

	const [page, setPage] = useState(0);

	const [order, setOrder] = useState("asc");

	const [selected, setSelected] = useState([]);

	const [orderBy, setOrderBy] = useState("name");

	const [filterName, setFilterName] = useState("");

	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [users, setUsers] = useState([]);
	const [receiverUser, setReceiverUser] = useState([]);

	const [selectedId, setSelectedId] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(true);
	const componentRef = useRef();

	const handleOpenMenu = (event, id, status) => {
		setOpenPopUp(event.currentTarget);
		setSelectedId(id);
		setSelectedStatus(status);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = users.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

	const fetchCategoriesTreeWise = async () => {
		const response = await axiosInstance.get(
			// "donation/get-all-receipt?user_id=7c5c54a4-56f2-49da-b9f0-a8cc5d91028a"
			`donation/get-all-receipt?user_id=${userInfo.user_id}&first_name=${enteredFirstName}&date&last_name=${enteredLastName}&donation_amount=${enteredAmount}&is_donation_approved=${enteredApprovalStatus}&donor_pan_card_number=${enteredPancard}`
		);

		if (response.data.status === true) {
			setUsers(response.data.data.result);
		}
	};

	useEffect(() => {
		fetchCategoriesTreeWise();
	}, [open1, openReasonModel]);

	const renderCategory = (users, parentActive = true) => {
		return (
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<UserListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={users?.length}
					numSelected={selected?.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{users
						?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						?.map((row) => {
							const { _id, name, status, parentId, children, avatarUrl } = row;
							const selectedUser = selected.indexOf(name) !== -1;

							return (
								<Row
									key={_id}
									row={row}
									handleOpenMenu={handleOpenMenu}
									renderCategory={renderCategory}
									parentActive={parentActive}
								/>
							);
						})}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
								<Paper
									sx={{
										textAlign: "center",
									}}
								>
									<Typography variant="h6" paragraph>
										Not found
									</Typography>

									<Typography variant="body2">
										No results found for &nbsp;
										<strong>&quot;{filterName}&quot;</strong>.
										<br /> Try checking for typos or using complete words.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
		);
	};

	const isNotFound = !users?.length && !!filterName;

	const [isEdit1, setIsEdit1] = React.useState(false);

	const onEdit = (data) => {
		setUsers((prev) => {
			if (prev.donation_id == data) {
				return { isEdited: true, ...prev };
			} else {
				return prev;
			}
		});
	};

	const onClickAcceptReceipt = async (receipt_id) => {
		const response = await axiosInstance.post("donation/approved-receipt", {
			user_id: userInfo.user_id,
			status: 2,
			status_reason: null,
			receipt_id: receipt_id,
		});

		if (response.data.message === "success") {
			toast.success("Receipt Accept Successfully");
			// setOpenReasonModel(false);
			window.location.reload();
		}
	};

	return (
		<div>
			<ToastContainer />
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				></Stack>

				{/* <UserListToolbar
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

				{/* <ScrollBar> */}

				{fullScreen ? (
					<>
						<div>
							{" "}
							<Typography variant="h5" gutterBottom>
								Receipt Management
							</Typography>
						</div>
						<div
							style={{
								// marginBottom: "10px",
								background: "#e5f2ff",
								display: "flex",
								// height: 40,
								padding: "10px",
							}}
						>
							<div
								style={{
									width: "50%",
								}}
							>
								{" "}
								<Button
									variant="contained"
									startIcon={<FilterAltIcon />}
									// onClick={() => {
									// 	navigate(`/admin/category/add`);
									// }}
									onClick={() => {
										handleClickOpenFilter();
									}}
									style={{ fontSize: "12px" }}
								>
									Filter{" "}
								</Button>
							</div>

							<div
								style={{
									width: "50%",
								}}
							>
								{" "}
								<Button
									variant="contained"
									startIcon={<Iconify icon="eva:plus-fill" />}
									// onClick={() => {
									// 	navigate(`/admin/category/add`);

									// }}

									style={{ fontSize: "12px" }}
									onClick={() => {
										handleClickOpen();
									}}
								>
									Receipt
								</Button>
							</div>
						</div>
						<Grid container spacing={1}>
							{users?.map((item) => {
								{
								}
								return (
									<>
										<Grid item md={6} sm={6} xs={12}>
											<>
												<Divider></Divider>
												{/* <CardMedia
													component="img"
													height="110"
													image={`https://eu.ui-avatars.com/api/?name=${item.first_name}+${item.last_name}&size=250`}
													alt="green iguana"
													style={{
														border: "6px solid #fff",
														marginBottom: "-60px",
														marginLeft: "31%",
														width: "110px",
														borderRadius: "100px",
													}}
												/> */}
												<Card sx={{ maxWidth: 345 }}>
													<CardContent ref={componentRef}>
														<Typography
															gutterBottom
															// variant="h6"
															component="div"
															textAlign={"center"}
														>
															Receipt #{item.donation_id}
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Full Name : </span>
															<span className="main-text">
																{item.donor_first_name} {item.donor_last_name}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">
																{" "}
																Mobile Number:{" "}
															</span>
															<span className="main-text">
																{item.donor_mobile_number}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Email : </span>
															<span className="main-text">
																{item.donor_email_id}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">
																Pancard Number:{" "}
															</span>
															<span className="main-text">
																{item.donor_pan_card_number}{" "}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">
																Donation Type:{" "}
															</span>
															<span className="main-text">
																{item.donation_type === 1 && "Donation"}
																{item.donation_type === 2 && "Rescue Fund"}
																{item.donation_type === 3 && "Cattle Fee"}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Payment Mode: </span>
															<span className="main-text">
																{item.payment_mode === 1 && "Online"}
																{item.payment_mode === 2 && "Cash"}
																{item.payment_mode === 3 && "Cheque"}
															</span>
															{/* {item.payment_mode} */}
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Received By: </span>

															{/* +91 {item.mobile_number} */}
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Amount: </span>₹{" "}
															{item.donation_amount}
														</Typography>

														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Status: </span>

															{item.is_donation_approved === 1 && (
																<span className="status-pending ">Pending</span>
															)}
															{item.is_donation_approved === 2 && (
																<span className="status-approved">
																	Approved
																</span>
															)}
															{item.is_donation_approved === 3 && (
																<span className="status-reject">
																	Not Approved
																</span>
															)}
														</Typography>
														{/* <Typography variant="body2" color="text.secondary">
															<span className="label-text">Address : </span>
															<span className="main-text">{item.address}</span>
														</Typography> */}
													</CardContent>
													{/* <Button
														style={{
															width: "100%",
															background: "#366aff",
															color: "#fff",
															fontSize: "10px",
															fontWeight: "900",
															borderRadius: "0px",
														}}
														onClick={() => {
															onViewRecipt(item.donation_id);
														}}
													>
														<RemoveRedEyeIcon /> View
													</Button> */}

													{/* <CardActions>
					<Button size="small" color="primary">
						Share
					</Button>
				</CardActions> */}
												</Card>
											</>{" "}
										</Grid>
									</>
								);
							})}
						</Grid>
					</>
				) : (
					<Card>
						<div
							style={{
								// marginBottom: "10px",
								background: "#e5f2ff",
								display: "flex",
								// height: 40,
								padding: "10px",
							}}
						>
							<div
								style={{
									width: "80%",
								}}
							>
								{" "}
								<Typography variant="h5" gutterBottom>
									Receipt Management
								</Typography>
							</div>
							<div
								style={{
									marginRight: "15px",
								}}
							>
								{" "}
								<Button
									variant="contained"
									startIcon={<FilterAltIcon />}
									// onClick={() => {
									// 	navigate(`/admin/category/add`);
									// }}
									onClick={() => {
										handleClickOpenFilter();
									}}
								>
									Filter
								</Button>
							</div>

							<div>
								{" "}
								<Button
									variant="contained"
									startIcon={<Iconify icon="eva:plus-fill" />}
									// onClick={() => {
									// 	navigate(`/admin/category/add`);
									// }}
									onClick={() => {
										handleClickOpen();
									}}
								>
									Receipt
								</Button>
							</div>
						</div>
						<Divider></Divider>
						<TableContainer component={Paper}>
							{renderCategory(users)}
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={users?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Card>
				)}

				{/* </ScrollBar> */}
			</Container>

			<Dialog
				// fullScreen
				open={open1}
				// scroll={scroll}
				onClose={handleClose}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Add Receipt
						</Typography>
						{/* <Button autoFocus color="inherit" onClick={handleClose}>
							Add User
						</Button> */}
					</Toolbar>
					<AddUser open1={open1} setOpen1={setOpen1} />
				</AppBar>
			</Dialog>
			<Dialog
				// fullScreen
				open={openFilter}
				// scroll={scroll}
				onClose={handleCloseFilter}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseFilter}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Add Filter
						</Typography>
						{/* <Button autoFocus color="inherit" onClick={handleClose}>
							Add User
						</Button> */}
					</Toolbar>
					<ReceiptFilter
						enteredFirstName={enteredFirstName}
						firstNameInputChangeHandler={firstNameInputChangeHandler}
						enteredLastName={enteredLastName}
						lastNameInputChangeHandler={lastNameInputChangeHandler}
						enteredAmount={enteredAmount}
						amountInputChangeHandler={amountInputChangeHandler}
						enteredApprovalStatus={enteredApprovalStatus}
						approvalStatusInputChangeHandler={approvalStatusInputChangeHandler}
						enteredCollectedBy={enteredCollectedBy}
						collectedByStatusInputChangeHandler={
							collectedByStatusInputChangeHandler
						}
						enteredPancard={enteredPancard}
						pancardStatusInputChangeHandler={pancardStatusInputChangeHandler}
						fetchCategoriesTreeWise={fetchCategoriesTreeWise}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
					/>
				</AppBar>
			</Dialog>
			<Dialog
				// fullScreen
				open={openView}
				// scroll={scroll}
				onClose={handleCloseView}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseView}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Receipt
						</Typography>
						{/* <Button autoFocus color="inherit" onClick={handleClose}>
							Add User
						</Button> */}
					</Toolbar>
					<ViewSingleReceipt ref={componentRef} receiptId={receiptId} />
				</AppBar>
			</Dialog>

			<Dialog
				// fullScreen
				open={openReasonModel}
				// scroll={scroll}
				onClose={handleCloseReasonModel}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseReasonModel}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Reason Of Reject Receipt
						</Typography>
					</Toolbar>
					<RejectedReceiptResoan
						receiptId={receiptId}
						setOpenReasonModel={setOpenReasonModel}
						openReasonModel={openReasonModel}
						users={users}
						setUsers={setUsers}
					/>
				</AppBar>
			</Dialog>
		</div>
	);
}

export default ReceiptTableMember;

function Row(props) {
	const userInfo = JSON.parse(localStorage.getItem("user"));

	const { row, handleOpenMenu, renderCategory, parentActive } = props;
	const [open, setOpen] = React.useState(false);

	const [openView, setOpenView] = React.useState(false);
	const [isEdit, setIsEdit] = React.useState(false);

	const [receiptId, setReceiptId] = React.useState(null);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const onViewRecipt = (item) => {
		setReceiptId(item);
		setOpenView(true);
	};
	const handleCloseView = () => {
		setOpenView(false);
	};

	const [openReasonModel, setOpenReasonModel] = React.useState(false);
	const onReasonModelOpen = (item) => {
		setReceiptId(item);
		setOpenReasonModel(true);
	};

	const handleCloseReasonModel = () => {
		setOpenReasonModel(false);
	};

	const onClickAcceptReceipt = async (receipt_id) => {
		const response = await axiosInstance.post("donation/approved-receipt", {
			user_id: userInfo.user_id,
			status: 2,
			status_reason: null,
			receipt_id: receipt_id,
		});

		if (response.data.message === "success") {
			toast.success("Receipt Accept Successfully");
			// setOpenReasonModel(false);
			window.location.reload();
		}
	};

	return (
		<>
			{/* {fullScreen ? } */}
			<TableRow
				hover
				key={row._id}
				tabIndex={-1}
				sx={{ background: row.user_status === 0 ? "#e9c7c7" : "" }}
			>
				<TableCell>
					<IconButton size="small" onClick={() => setOpen(!open)}>
						{/* {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
					</IconButton>
				</TableCell>

				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.donor_first_name}
						</Typography>
					</Stack>
				</TableCell>
				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.donor_last_name}
						</Typography>
					</Stack>
				</TableCell>
				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.donation_amount}
						</Typography>
					</Stack>
				</TableCell>
				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.donor_email_id}
						</Typography>
					</Stack>
				</TableCell>

				<TableCell align="left">{row.donor_mobile_number}</TableCell>

				<TableCell align="left">{row.donor_pan_card_number}</TableCell>

				<TableCell align="left">
					<>
						{" "}
						{row.is_donation_approved === 1 && (
							<Label color="warning">Pending</Label>
						)}
						{row.is_donation_approved === 2 && (
							<Label color="success">Approved</Label>
						)}
						{row.is_donation_approved === 3 && (
							<Label color="error">Not Approved</Label>
						)}
					</>
				</TableCell>
				<TableCell>
					<IconButton
						onClick={() => {
							onViewRecipt(row.donation_id);
						}}
						style={{ color: "Blue" }}
						aria-label="delete"
					>
						<RemoveRedEyeIcon />
					</IconButton>
				</TableCell>
			</TableRow>

			{row.children && row.children?.length > 0 && (
				<TableRow sx={{ background: row.status === false ? "#e9c7c7" : "" }}>
					<TableCell
						style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
						colSpan={12}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{renderCategory(
								row.children,
								parentActive && row.status ? true : false
							)}
						</Collapse>
					</TableCell>
				</TableRow>
			)}
			<Dialog
				// fullScreen
				open={openView}
				// scroll={scroll}
				onClose={handleCloseView}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseView}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Receipt
						</Typography>
						{/* <Button autoFocus color="inherit" onClick={handleClose}>
							Add User
						</Button> */}
					</Toolbar>
					<ViewSingleReceipt receiptId={receiptId} />
				</AppBar>
			</Dialog>
			<Dialog
				// fullScreen
				open={openReasonModel}
				// scroll={scroll}
				onClose={handleCloseReasonModel}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseReasonModel}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Reason Of Reject Receipt
						</Typography>
					</Toolbar>
					<RejectedReceiptResoan
						receiptId={receiptId}
						setOpenReasonModel={setOpenReasonModel}
						openReasonModel={openReasonModel}
						// users={users}
						// setUsers={setUsers}
					/>
				</AppBar>
			</Dialog>
		</>
	);
}
