import { sentenceCase } from "change-case";
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Checkbox,
	Collapse,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Popover,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@mui/material";
import "./users.css";
import React, { useEffect, useState } from "react";
// import UserListToolbar from "../../components/UserListToolbar";
import axiosInstance from "../../../utils/AxiosHelper";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// import USERLIST from "../../_mock/user";
import { filter } from "lodash";
import Iconify from "../../../components/Iconify";
import UserListHead from "../../../components/UserListHead";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import Label from "../../../components/label/Label";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddUser from "./AddUser";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: "first_name", label: "First Name", alignRight: false },
	{ id: "last_name", label: "Last Name", alignRight: false },
	{ id: "email_id", label: "Email", alignRight: false },
	{ id: "mobile_number", label: "Mobile Number", alignRight: false },
	{ id: "address", label: "Address", alignRight: false },
	{ id: "user_status", label: "User Status", alignRight: false },
	// { id: "address", label: "Has Children", alignRight: false },
	// { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function UserList() {
	const navigate = useNavigate();
	const userInfo = JSON.parse(localStorage.getItem("user"));

	const [openPopUp, setOpenPopUp] = useState(null);
	const [open, setOpen] = React.useState(false);
	const [open1, setOpen1] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen1(true);
	};

	const handleClose = () => {
		setOpen1(false);
	};

	const [page, setPage] = useState(0);

	const [order, setOrder] = useState("asc");

	const [selected, setSelected] = useState([]);

	const [orderBy, setOrderBy] = useState("name");

	const [filterName, setFilterName] = useState("");

	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [users, setUsers] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(true);

	const handleOpenMenu = (event, id, status) => {
		setOpenPopUp(event.currentTarget);
		setSelectedId(id);
		setSelectedStatus(status);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = users.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

	const fetchCategoriesTreeWise = async () => {
		const response = await axiosInstance.get(
			`donation/get-users?user_id=${userInfo.user_id}`
		);

		if (response.data.status === true) {
			setUsers(response.data.data.result);
		}
	};

	useEffect(() => {
		fetchCategoriesTreeWise();
	}, [open1]);

	const renderCategory = (users, parentActive = true) => {
		return (
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<UserListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={users?.length}
					numSelected={selected?.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{users
						?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						?.map((row) => {
							const { _id, name, status, parentId, children, avatarUrl } = row;
							const selectedUser = selected.indexOf(name) !== -1;

							return (
								<Row
									key={_id}
									row={row}
									handleOpenMenu={handleOpenMenu}
									renderCategory={renderCategory}
									parentActive={parentActive}
								/>
							);
						})}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
								<Paper
									sx={{
										textAlign: "center",
									}}
								>
									<Typography variant="h6" paragraph>
										Not found
									</Typography>

									<Typography variant="body2">
										No results found for &nbsp;
										<strong>&quot;{filterName}&quot;</strong>.
										<br /> Try checking for typos or using complete words.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
		);
	};

	const isNotFound = !users?.length && !!filterName;

	return (
		<>
			<ToastContainer />
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
					style={{ marginTop: "10px" }}
				>
					<Typography variant="h4" gutterBottom>
						{!fullScreen && "User Management"}
					</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:plus-fill" />}
						// onClick={() => {
						// 	navigate(`/admin/category/add`);
						// }}
						onClick={() => {
							handleClickOpen();
						}}
					>
						New User
					</Button>
				</Stack>

				{/* <UserListToolbar
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

				{/* <ScrollBar> */}

				{fullScreen ? (
					<>
						<Grid container spacing={1}>
							<div style={{ textAlign: "center", width: "100%" }}>
								{" "}
								<Typography variant="h5" gutterBottom>
									User Management
								</Typography>
							</div>
							<div
								style={{
									// marginBottom: "10px",
									background: "#e5f2ff",
									display: "flex",
									// height: 40,
									padding: "10px",
								}}
							></div>
							{users?.map((item) => {
								return (
									<>
										<Grid item md={6} sm={6} xs={12}>
											<>
												<CardMedia
													component="img"
													height="110"
													image={`https://eu.ui-avatars.com/api/?name=${item.first_name}+${item.last_name}&size=250`}
													alt="green iguana"
													style={{
														border: "6px solid #fff",
														marginBottom: "-60px",
														marginLeft: "31%",
														width: "110px",
														borderRadius: "100px",
													}}
												/>
												<Card sx={{ maxWidth: 345 }}>
													<CardContent style={{ marginTop: "50px" }}>
														<Label
															color={
																item.user_status === 0 ? "error" : "success"
															}
														>
															{item.user_status === 0 ? "Inactive" : "Active"}
														</Label>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">First Name : </span>
															<span className="main-text">
																{item.first_name}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Last Name : </span>
															<span className="main-text">
																{item.last_name}
															</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Email : </span>
															<span className="main-text">{item.email_id}</span>
														</Typography>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
														>
															<span className="label-text">Mobile : </span>
															<span className="main-text">
																+91 {item.mobile_number}
															</span>
															{/* +91 {item.mobile_number} */}
														</Typography>
														<Typography variant="body2" color="text.secondary">
															<span className="label-text">Address : </span>
															<span className="main-text">{item.address}</span>
														</Typography>
													</CardContent>
													{item.user_status === 1 ? (
														<Button
															style={{
																width: "100%",
																background: "red",
																color: "#fff",
																fontSize: "10px",
																fontWeight: "900",
															}}
														>
															Click to De-Active User Account
														</Button>
													) : (
														<Button
															style={{
																width: "100%",
																background: "green",
																color: "#fff",
																fontSize: "10px",
																fontWeight: "900",
															}}
														>
															Click to Active User Account
														</Button>
													)}

													{/* <CardActions>
					<Button size="small" color="primary">
						Share
					</Button>
				</CardActions> */}
												</Card>
											</>{" "}
										</Grid>
									</>
								);
							})}
						</Grid>
					</>
				) : (
					<Card>
						<TableContainer component={Paper}>
							{renderCategory(users)}
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={users?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Card>
				)}

				{/* </ScrollBar> */}
			</Container>

			<Dialog
				// fullScreen
				open={open1}
				// scroll={scroll}
				onClose={handleClose}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				// style={{ paddingRight: "850px" }}
				// className="dialog-model-main"
				className={!fullScreen ? "dialog-model-main" : ""}
			>
				<AppBar
					className={!fullScreen ? "model-width" : ""}
					sx={{ position: "relative", p: 0 }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							ADD USER
						</Typography>
						{/* <Button autoFocus color="inherit" onClick={handleClose}>
							Add User
						</Button> */}
					</Toolbar>
					<AddUser open1={open1} setOpen1={setOpen1} />
				</AppBar>
			</Dialog>
		</>
	);
}

export default UserList;

function Row(props) {
	const { row, handleOpenMenu, renderCategory, parentActive } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<>
			{/* {fullScreen ? } */}
			<TableRow
				hover
				key={row._id}
				tabIndex={-1}
				sx={{ background: row.user_status === 0 ? "#e9c7c7" : "" }}
			>
				<TableCell>
					<IconButton size="small" onClick={() => setOpen(!open)}>
						{/* {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
					</IconButton>
				</TableCell>

				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.first_name}
						</Typography>
					</Stack>
				</TableCell>
				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.last_name}
						</Typography>
					</Stack>
				</TableCell>
				<TableCell component="th" scope="row">
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="subtitle2" noWrap>
							{row.email_id}
						</Typography>
					</Stack>
				</TableCell>

				<TableCell align="left">{row.mobile_number}</TableCell>

				<TableCell align="left">{row.address}</TableCell>

				<TableCell align="left">
					<Label color={row.user_status === 0 ? "error" : "success"}>
						{row.user_status === 0 ? "Inactive" : "Active"}
					</Label>
				</TableCell>
			</TableRow>

			{row.children && row.children?.length > 0 && (
				<TableRow sx={{ background: row.status === false ? "#e9c7c7" : "" }}>
					<TableCell
						style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
						colSpan={12}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{renderCategory(
								row.children,
								parentActive && row.status ? true : false
							)}
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}
