import "./App.css";
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
	useLocation,
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ErrorPage from "./components/ErrorPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./context/AuthContext";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Layout from "./components/Layout";
import DashboardUI from "./pages/dashboard/DashboardUI";
import Category from "./pages/dashboard/Category";
import Product from "./pages/dashboard/Product";
import AddCategory from "./components/category/AddCategory";
import EditCategory from "./components/category/EditCategory";
import AdminDashboard from "./pages/admin/dashboard";
import UserList from "./pages/admin/Users/UserList";
import ReceiptTable from "./pages/admin/Receipt/ReceiptTable";
import MemberDashboard from "./pages/member/dashboard";
import ReceiptTableMember from "./pages/member/Receipt/ReceiptTable";
import VeryfyRecipts from "./pages/VeryfyRecipts";

const adminRouter = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to={"/admin/dashboard"} />,
	},
	{
		path: "/home",
		element: <Home />,
	},
	{
		path: "/admin",
		element: <Dashboard />,
		children: [
			{
				path: "/admin/dashboard",
				element: <AdminDashboard />,
			},
			{
				path: "/admin/userlist",
				element: <UserList />,
			},
			{
				path: "/admin/receipt-list",
				element: <ReceiptTable />,
			},
			{ path: "/admin/change-password", element: <ResetPassword /> },
			// {
			// 	path: "/admin/category/add",
			// 	element: <AddCategory />,
			// },
			// {
			// 	path: "/admin/category/edit/:id",
			// 	element: <EditCategory />,
			// },
			// {
			// 	path: "/admin/products",
			// 	element: <Product />,
			// },
			// {
			// 	path: "/admin/products/add",
			// 	element: <AddCategory />,
			// },
			// {
			// 	path: "/admin/products/edit/:id",
			// 	element: <EditCategory />,
			// },
		],
	},
	{
		path: "*",
		element: <Navigate to={"/admin/dashboard"} />,
	},
]);

const memberRouter = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to={"/member/dashboard"} />,
	},
	{
		path: "/member",
		element: <Dashboard />,
		children: [
			{
				path: "/member/dashboard",
				element: <MemberDashboard />,
			},
			{
				path: "/member/receipt-list",
				element: <ReceiptTableMember />,
			},
			{ path: "/member/change-password", element: <ResetPassword /> },
		],
	},
	{
		path: "*",
		element: <Navigate to={"/member/dashboard"} />,
	},
]);

const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to={"/home"} />,
	},
	{
		path: "/home",
		element: <Home />,
	},
	{
		path: "*",
		element: <Navigate to={"/home"} />,
	},
]);

const authRouter = createBrowserRouter([
	{
		path: "/",
		element: <Login />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/signUp",
		element: <SignUp />,
	},
	{
		path: "/forgot-password",
		element: <ForgotPassword />,
	},
	// {
	// 	path: "/resetPassword/:token",
	// 	element: <ResetPassword />,
	// },
	{
		path: "/verify-donations",
		element: <VeryfyRecipts />,
	},
	{
		path: "*",
		element: <Navigate to={"/"} />,
	},
]);

function App() {
	const authCtx = useContext(AuthContext);
	// const [isDashboardPage, setIsDashboardPage] = useState(false);

	// useEffect(() => {
	//   setIsDashboardPage(window.location.href.includes("admin"));
	// }, [authCtx]);

	return (
		<div className="App">
			{authCtx.isAuthenticated ? (
				// <>
				// 	{authCtx?.currentUser?.user_role === 1 ? (
				// 		<RouterProvider router={adminRouter} />
				// 	) : authCtx?.currentUser?.user_role === 2 ? (
				// 		<RouterProvider router={memberRouter} />
				// 	) : (
				// 		<>
				// 			<ResponsiveAppBar />
				// 			<RouterProvider router={router} />
				// 		</>
				// 	)}
				// </>
				<>
					{authCtx?.currentUser?.user_role === 1 ? (
						<RouterProvider router={adminRouter} />
					) : (
						<RouterProvider router={memberRouter} />
					)}
				</>
			) : (
				<RouterProvider router={authRouter} />
			)}
			{/* <ResponsiveAppBar /> */}
			{/* <RouterProvider router={router} /> */}

			{/* <RouterProvider router={adminRouter} />
			<ToastContainer /> */}
		</div>
	);
}

export default App;
