import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";

// third-party
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

// project imports
import MainCard from "../../../components/cards/MainCard";
// import { gridSpacing } from "store/constant";

// chart data
import TotalGrowthBarCharts from "./Chart-data/TotalGrowthBarChart";

const status = [
	{
		value: "today",
		label: "Today",
	},
	{
		value: "month",
		label: "This Month",
	},
	{
		value: "year",
		label: "This Year",
	},
];

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const TotalGrowthBarChart = ({ isLoading }) => {
	return (
		<>
			<MainCard style={{ borderRadius: "10px" }} border={false}>
				<TotalGrowthBarCharts />
			</MainCard>
		</>
	);
};

export default TotalGrowthBarChart;
