import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Avatar, Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import Chart from "react-apexcharts";

// project imports
import MainCard from "../../../components/cards/MainCard";
import SkeletonEarningCard from "../../../components/cards/Skeleton/EarningCard";

// assets
import EarningIcon from "../../../asset/icons/earning.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppOutlined";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyOutlined";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveOutlined";
import approvalPendingData from "./Chart-data/approvalPendingData";
// import ChartDataYear from "./Chart-data/total-order-year-line-chart";

const CardWrapper = styled(MainCard)(({ theme }) => ({
	backgroundColor: "rgb(30, 136, 229)",
	color: "#fff",
	overflow: "hidden",
	position: "relative",
	"&:after": {
		content: '""',
		position: "absolute",
		width: 210,
		height: 210,
		background: "rgb(21, 101, 192)",
		borderRadius: "50%",
		top: -85,
		right: -95,
		[theme.breakpoints.down("sm")]: {
			top: -105,
			right: -140,
		},
	},
	"&:before": {
		content: '""',
		position: "absolute",
		width: 210,
		height: 210,
		background: "rgb(21, 101, 192)",
		borderRadius: "50%",
		top: -125,
		right: -15,
		opacity: 0.5,
		[theme.breakpoints.down("sm")]: {
			top: -155,
			right: -70,
		},
	},
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ApprovalPendingCard = ({ isLoading }) => {
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{isLoading ? (
				<SkeletonEarningCard />
			) : (
				<CardWrapper
					style={{ borderRadius: "10px" }}
					border={false}
					content={false}
				>
					<Box sx={{ p: 2.25 }}>
						<Grid container direction="column">
							<Grid item>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Avatar
											variant="rounded"
											sx={{
												...theme.typography.commonAvatar,
												...theme.typography.largeAvatar,
												backgroundColor: "rgb(21, 101, 192)",
												mt: 1,
											}}
										>
											<img src={EarningIcon} alt="Notification" />
										</Avatar>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container alignItems="center">
									<Grid item>
										<Typography
											sx={{
												fontSize: "2.125rem",
												fontWeight: 500,
												mr: 1,
												mt: 1.75,
												mb: 0.75,
											}}
										>
											$500.00
										</Typography>
									</Grid>
									<Grid item>
										<Avatar
											sx={{
												cursor: "pointer",
												...theme.typography.smallAvatar,
												backgroundColor: "rgb(144, 202, 249)",
												color: "rgb(21, 101, 192)",
											}}
										>
											<ArrowUpwardIcon
												fontSize="inherit"
												sx={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
											/>
										</Avatar>
									</Grid>
								</Grid>
							</Grid>
							<Grid item sx={{ mb: 1.25 }}>
								<Typography
									sx={{
										fontSize: "1rem",
										fontWeight: 500,
										color: "rgb(144, 202, 249)",
									}}
								>
									Total Earning
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</CardWrapper>
			)}
		</>
	);
};

ApprovalPendingCard.propTypes = {
	isLoading: PropTypes.bool,
};

export default ApprovalPendingCard;
