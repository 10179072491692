import React from "react";
import EarningCard from "./EarningCard";
import { Grid } from "@mui/material";
import TotalOrderLineChartCard from "./TotalOrderLineChartCard";
import ApprovalPendingCard from "./ApprovalPendingCard";
import TotalGrowthBarChart from "./TotalGrowthBarChart";

const MemberDashboard = () => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid container>
					<Grid style={{ margin: "10px" }} item lg={4} md={6} sm={6} xs={12}>
						<EarningCard />
					</Grid>
					<Grid style={{ margin: "10px" }} item lg={4} md={6} sm={6} xs={12}>
						<TotalOrderLineChartCard />
					</Grid>{" "}
					<Grid style={{ margin: "10px" }} item lg={3} md={6} sm={6} xs={12}>
						<ApprovalPendingCard />
					</Grid>
					{/* <Grid style={{ margin: "1px" }} item lg={3} md={6} sm={6} xs={12}>
						<TotalOrderLineChartCard />
					</Grid> */}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid style={{ margin: "10px" }} item lg={12} xs={12} md={8}>
						<TotalGrowthBarChart />
					</Grid>
					<Grid item xs={12} md={4}>
						{/* <PopularCard/> */}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MemberDashboard;
