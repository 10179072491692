import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import useInput from "../../../hooks/useInput";
import axiosInstance from "../../../utils/AxiosHelper";
import "../../../Auth.css";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";

function RejectedReceiptResoan({
	receiptId,
	openReasonModel,
	setOpenReasonModel,
	users,
	setUsers,
}) {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const userInfo = JSON.parse(localStorage.getItem("user"));

	const [isOtpPage, setisOtpPage] = useState(false);
	const [otp, setOtp] = useState(null);

	let {
		enteredInput: enteredReason,
		inputValid: reasonInputIsValid,
		inputInvalid: reasonInputIsInvalid,
		blurHandler: reasonInputBlurHandler,
		changeHandler: reasonInputChangeHandler,
		reset: reasonInputReset,
	} = useInput("", (value) => value !== "");

	const signUpHandler = async (e) => {
		e.preventDefault();

		reasonInputBlurHandler();

		if (!enteredReason) {
			return;
		}

		const response = await axiosInstance.post("donation/approved-receipt", {
			user_id: userInfo.user_id,
			status: 3,
			status_reason: enteredReason,
			receipt_id: receiptId,
		});

		if (response.data.message === "success") {
			toast.success("Receipt Rejected Successfully");
			setOpenReasonModel(false);
			window.location.reload();
		}
	};

	return (
		<div style={{ background: "#e5f2ff", padding: "15px" }} sx={{}} id="app">
			<div style={{ width: "100%" }}>
				{/* <header>
					<div className={"header-headings"}>
						<span>
							{!isOtpPage
								? "Create new account"
								: "Enter your verification code"}
						</span>
					</div>
				</header> */}

				<form className="account-form" onSubmit={signUpHandler}>
					<div className={"account-form-fields"}>
						<Grid container spacing={1}>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<InputLabel id="demo-simple-select-helper-label">
										Reason of Rejection
									</InputLabel>
									<input
										id="ReasonofRejection"
										name="ReasonofRejection"
										type="text"
										placeholder="Reason of Rejection"
										value={enteredReason}
										onChange={reasonInputChangeHandler}
										onBlur={reasonInputBlurHandler}
									/>
									{reasonInputIsInvalid && (
										<p className="error-text">Please enter valid Reason</p>
									)}{" "}
								</div>
							</Grid>
						</Grid>
					</div>
					<button className="btn-submit-form" type="submit">
						Reject Receipt
					</button>
				</form>
				{/* <div className="btn-submit-form" style={{ marginTop: "100px" }}>
					<Link to="/login">Already have an account</Link>
				</div> */}
			</div>
		</div>
	);
}

export default RejectedReceiptResoan;
