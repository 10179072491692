import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosHelper";
import "../Auth.css";
import { AuthContext } from "../context/AuthContext";

function ResetPassword() {
	const params = useParams();
	const navigate = useNavigate();

	const authCtx = React.useContext(AuthContext);

	let {
		enteredInput: enteredPassword,
		inputValid: passwordInputIsValid,
		inputInvalid: passwordInputIsInvalid,
		blurHandler: passwordInputBlurHandler,
		changeHandler: passwordInputChangeHandler,
		reset: passwordInputReset,
	} = useInput("", (value) => value !== "" && value.length >= 8);

	let {
		enteredInput: enteredConfirmPassword,
		inputValid: confirmPasswordInputIsValid,
		inputInvalid: confirmPasswordInputIsInvalid,
		blurHandler: confirmPasswordInputBlurHandler,
		changeHandler: confirmPasswordInputChangeHandler,
		reset: confirmPasswordInputReset,
	} = useInput("", (value) => value === enteredPassword && value.length >= 8);

	const resetPasswordHandler = async (e) => {
		e.preventDefault();

		passwordInputBlurHandler();
		confirmPasswordInputBlurHandler();

		if (
			!(enteredPassword.length >= 8) ||
			enteredPassword !== enteredConfirmPassword
		) {
			return;
		}
		const response = await axiosInstance.post(`auth/change-password`, {
			email_id: authCtx?.currentUser?.email_id,
			newpassword: enteredPassword,
		});

		if (response.data.status === true) {
			toast.success("Password Change successfully!");

			passwordInputReset();
			confirmPasswordInputReset();
			// navigate("/login");
		}
	};

	return (
		<div id="app" style={{ height: "100vh" }}>
			<ToastContainer></ToastContainer>
			<div className="container">
				<header>
					<div className={"header-headings"} style={{ color: "#000" }}>
						<span>Change your password</span>
					</div>
				</header>

				<form className="account-form" onSubmit={resetPasswordHandler}>
					<div className={"account-form-fields"}>
						<>
							<input
								id="password"
								name="password"
								type="password"
								placeholder="Password"
								value={enteredPassword}
								onChange={passwordInputChangeHandler}
								onBlur={passwordInputBlurHandler}
							/>
							{passwordInputIsInvalid && (
								<p className="error-text">Please enter atleast 8 character!</p>
							)}
							<input
								id="confirmPassword"
								name="confirmPassword"
								type="password"
								placeholder="Confirm password"
								value={enteredConfirmPassword}
								onChange={confirmPasswordInputChangeHandler}
								onBlur={confirmPasswordInputBlurHandler}
							/>
							{confirmPasswordInputIsInvalid && (
								<p className="error-text">Password should be match!</p>
							)}
						</>
					</div>
					<button className="btn-submit-form" type="submit">
						Change Password
					</button>
				</form>

				{/* <div className="btn-submit-form" style={{ marginTop: "100px" }}>
          <Link to="/signUp">Create new account</Link>
        </div> */}
			</div>
		</div>
	);
}

export default ResetPassword;
