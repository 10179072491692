import React, { useState } from "react";
import Pdf from "react-to-pdf";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LanguageIcon from "@mui/icons-material/Language";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosHelper";
import "../Auth.css";
import logo from "../asset/icons/logo_final.png";
import { Divider } from "@mui/material";
const numWords = require("num-words");

function VeryfyRecipts() {
	const [steps, setSteps] = useState(1);
	const [isReceiptDownload, setIsReceiptDownload] = useState(false);

	const [data, setData] = useState(null);
	const ref = React.createRef();

	let {
		enteredInput: enteredReceiptNo,
		inputValid: receiptNoInputIsValid,
		inputInvalid: receiptNoInputIsInvalid,
		blurHandler: receiptNoInputBlurHandler,
		changeHandler: receiptNoInputChangeHandler,
		reset: receiptNoInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredPancard,
		inputValid: pancardInputIsValid,
		inputInvalid: pancardInputIsInvalid,
		blurHandler: pancardInputBlurHandler,
		changeHandler: pancardInputChangeHandler,
		reset: pancardInputReset,
	} = useInput("", (value) => value !== "");

	let receipt_no = enteredReceiptNo?.split("#");

	const receipVeryfyHandler = async (e) => {
		e.preventDefault();

		receiptNoInputBlurHandler();
		pancardInputBlurHandler();
		if (!enteredReceiptNo && !enteredPancard) {
			return;
		}

		const response = await axiosInstance.get(
			`donation/veryfy-receipt-details?receipt_id=${receipt_no[1]}&pancard=${enteredPancard}`
		);

		if (response.data.message === "success") {
			setData(response.data.data.result);
			setSteps(2);
			// setNewPasswordPage(true);
			// console.log("Mail sent as URL");
			// toast.success("Password reset URL sent to your email address!");
		}
	};

	return (
		<div id="app">
			{steps === 1 && (
				<div className="container" style={{ display: "block" }}>
					<header>
						<div className={"header-headings"}>
							<span> Donation Verify</span>
						</div>
					</header>

					<form className="account-form" onSubmit={receipVeryfyHandler}>
						<div className={"account-form-fields"}>
							<input
								id="enateraRecieptNo"
								name="enateraRecieptNo"
								type="text"
								placeholder="Enter Reciept No"
								value={enteredReceiptNo}
								onChange={receiptNoInputChangeHandler}
								onBlur={receiptNoInputBlurHandler}
							/>
							{receiptNoInputIsInvalid && (
								<p className="error-text">Please enter valid Receipt No!</p>
							)}
						</div>{" "}
						<div className={"account-form-fields"}>
							<input
								id="pancard"
								name="pancard"
								type="text"
								placeholder="Enter Pancard Number"
								value={enteredPancard}
								onChange={pancardInputChangeHandler}
								onBlur={pancardInputBlurHandler}
							/>
							{pancardInputIsInvalid && (
								<p className="error-text">Please enter valid Pancard!</p>
							)}
						</div>
						<button className="btn-submit-form" type="submit">
							Verify Receipt
						</button>
					</form>
				</div>
			)}
			{steps === 2 && (
				<div className="container-receipt" style={{ background: "#fff" }}>
					<div className="receipt-logo">
						<img src={logo} className="App-logo" alt="logo" width={100} />
					</div>
					<div className="text-pan">
						<div className="receipt-text-label">
							Receipt No : 4leg#{data?.donation_details?.donation_id}
						</div>
						<div className="receipt-text-label">
							Pancard No : {data?.donation_details?.donor_pan_card_number}
						</div>
					</div>

					<div>
						<div className="receipt-donor-name">
							Dear {data?.donation_details?.donor_first_name}&nbsp;
							{data?.donation_details?.donor_last_name}
						</div>
						<div className="receipt-thankyou-msg ">
							Thank you for your generous gift to 4Leg Rescue Foundation. We are
							thrilled to have your support. Through your donation, we have been
							able to accomplish help stray animals and continue working to give
							better facilities to animals. You truly make a difference for us,
							and we are extremely grateful!
						</div>
						<div className="receipt-donor-name">4Leg Rescue Foundation.</div>
					</div>
					{data?.donation_details?.is_donation_approved === 1 && (
						<div className="receipt-information-section-pending">
							Receipt Verification Status : Pending
							<div className="receipt-information-contact">
								If You have any query free feel contact us on +91 97232 63162 or
								send mail on info@4LegRescue.com
							</div>
						</div>
					)}
					{data?.donation_details?.is_donation_approved === 3 && (
						<div className="receipt-information-section-rejected">
							<div>Receipt Verification Status : Rejected</div>
							<div className="receipt-information-contact">
								If You have any query free feel contact us on +91 97232 63162 or
								send mail on info@4LegRescue.com
							</div>
						</div>
					)}
					{data?.donation_details?.is_donation_approved === 2 && (
						<>
							<div className="receipt-information-section">
								<div className="receipt-information-name">
									<span>Received with thanks from :&nbsp;</span>
									{data?.donation_details?.donor_first_name} &nbsp;
									{data?.donation_details?.donor_last_name},
								</div>
								<div className="receipt-information-name">
									{data?.donation_details?.donor_address1} ,
									{data?.donation_details?.donor_address2},
									{data?.donation_details?.city},{" "}
									{data?.donation_details?.state},
									{data?.donation_details?.pincode},
								</div>
								<div className="receipt-information-name">
									<span>The Sum of &nbsp;</span>INR{" "}
									{data?.donation_details?.donation_amount} (INR&nbsp;
									{numWords(`${data?.donation_details?.donation_amount}`)} only)
								</div>
								<div className="receipt-information-80g">
									(** Contributions to 4leg Rescue foundation Donations have
									been 100% deduction from taxable income under section 80G of
									Income Tax Act,1961)
								</div>
							</div>
							<div style={{ width: "100%" }}>
								<Pdf
									targetRef={ref}
									options={{ unit: "px", format: [638, 451] }}
									x={5}
									y={11}
									filename="donation-receipt.pdf"
									onComplete={() => {
										setIsReceiptDownload(false);
									}}
								>
									{({ toPdf }) => (
										<button
											onClick={() => {
												setIsReceiptDownload(true);
												toPdf();
											}}
											className="receipt-download-CTA "
										>
											Click here to download receipt
										</button>
									)}
								</Pdf>
							</div>
						</>
					)}

					<div
						style={{
							// borderRadius: "10px",
							margin: "10px",
							border: `${isReceiptDownload ? "2px solid #000e52" : "none"}`,
							// padding: "0px",
							width: "780px",
						}}
						ref={ref}
					>
						{isReceiptDownload && (
							<div
								style={{
									// borderRadius: "10px",
									height: "1100px",
									width: "780px",

									padding: "0px",
								}}
								// ref={componentRef}
							>
								{/* Header Part */}
								<div style={{ display: "flex", padding: "16px" }}>
									<div style={{ paddingTop: "50px" }}>
										<img
											src={logo}
											className="App-logo"
											alt="logo"
											width={150}
											paddingTop={50}
										/>
									</div>
									<div style={{ padding: "15px" }}>
										<div
											style={{
												fontFamily: "Poppins",
												fontSize: "24px",
												fontWeight: "500",
												color: "#001b4c",
												textAlign: "left",
												marginTop: "25px",
											}}
										>
											4LEG RESCUE FOUNDATION
										</div>

										<div style={{ display: "flex" }}>
											<div style={{ color: "#00053e" }}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
													id="IconChangeColor"
													height="25px"
													width="25px"
												>
													<path
														d="M285.6 444.1C279.8 458.3 264.8 466.3 249.8 463.4C234.8 460.4 223.1 447.3 223.1 432V256H47.1C32.71 256 19.55 245.2 16.6 230.2C13.65 215.2 21.73 200.2 35.88 194.4L387.9 50.38C399.8 45.5 413.5 48.26 422.6 57.37C431.7 66.49 434.5 80.19 429.6 92.12L285.6 444.1z"
														id="mainIconPathAttribute"
													></path>
												</svg>
											</div>
											<div
												style={{
													fontFamily: "Poppins",
													fontSize: "13px",
													fontWeight: "500",
													color: "#001b4c",
													textAlign: "left",
													// marginTop: "2px",
													marginLeft: "10px",
												}}
											>
												"Vaikunthdham", Plot No - 104, Opp. Tekri Vala Hanuman,
												Opp. Power Station, Kamod Cow Circle, Kamod, Ahmedabad -
												382405
											</div>
										</div>
										<div
											style={{
												display: "flex",
												marginLeft: "5px",
												marginTop: "5px",
											}}
										>
											<div style={{ display: "flex" }}>
												<div style={{ color: "#00053e" }}>
													{/* <CallIcon
													style={{ color: "#00053e", fontSize: "18px" }}
												/> */}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="13"
														height="13"
														fill="currentColor"
														class="bi bi-telephone-inbound-fill"
														viewBox="0 0 16 16"
														id="IconChangeColor"
													>
														{" "}
														<path
															fill-rule="evenodd"
															d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z"
															id="mainIconPathAttribute"
															stroke="#00053e"
														></path>{" "}
													</svg>
												</div>
												<div
													style={{
														fontFamily: "Poppins",
														fontSize: "13px",
														fontWeight: "500",
														color: "#001b4c",
														textAlign: "left",
														// marginTop: "2px",
														marginLeft: "10px",
													}}
												>
													+91 7574 802 802
												</div>
											</div>
											<div style={{ display: "flex", marginLeft: "20px" }}>
												<div style={{ color: "#00053e" }}>
													{/* <MarkEmailReadIcon
													style={{ color: "#00053e", fontSize: "18px" }}
												/> */}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="14"
														height="14"
													>
														<path d="M12,19c0-3.87,3.13-7,7-7c1.08,0,2.09,0.25,3,0.68V6c0-1.1-0.9-2-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h8.08 C12.03,19.67,12,19.34,12,19z M4,6l8,5l8-5v2l-8,5L4,8V6z M17.34,22l-3.54-3.54l1.41-1.41l2.12,2.12l4.24-4.24L23,16.34L17.34,22z" />
													</svg>
												</div>
												<div
													style={{
														fontFamily: "Poppins",
														fontSize: "13px",
														fontWeight: "500",
														color: "#001b4c",
														textAlign: "left",
														marginLeft: "10px",
													}}
												>
													info@4LegRescue.com
												</div>
											</div>
										</div>
										<div style={{ display: "flex" }}>
											<div style={{ color: "#00053e" }}>
												{/* <LanguageIcon style={{ color: "#00053e" }} /> */}
												<svg
													style={{ color: "rgb(0, 5, 62)", marginLeft: "4px" }}
													width="16"
													height="16"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													{" "}
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M14 7C13.4477 7 13 7.44772 13 8V16C13 16.5523 13.4477 17 14 17H18C18.5523 17 19 16.5523 19 16V8C19 7.44772 18.5523 7 18 7H14ZM17 9H15V15H17V9Z"
														fill="#00053e"
													></path>{" "}
													<path
														d="M6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H10C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7H6Z"
														fill="#00053e"
													></path>{" "}
													<path
														d="M6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11H6Z"
														fill="#00053e"
													></path>{" "}
													<path
														d="M5 16C5 15.4477 5.44772 15 6 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H6C5.44772 17 5 16.5523 5 16Z"
														fill="#00053e"
													></path>{" "}
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4ZM20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z"
														fill="#00053e"
													></path>
												</svg>
											</div>
											<div
												style={{
													fontFamily: "Poppins",
													fontSize: "13px",
													fontWeight: "500",
													color: "#001b4c",
													textAlign: "left",
													// marginTop: "2px",
													marginLeft: "10px",
												}}
											>
												www.4LegRescue.com{" "}
											</div>
										</div>
									</div>
								</div>
								{/* Header Part */}

								{/* Date & Receipt No Part */}

								<div>
									<Divider style={{ color: "#000e52" }}></Divider>
								</div>
								<div
									style={{
										display: "flex",
										paddingTop: "10px",
										padding: "16px",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											width: "50%",
											fontFamily: "Raleway",
											fontSize: "15px",
											fontWeight: "500",
											color: "#001b4c",
										}}
									>
										<span>Receipt No :&nbsp;</span>
										<span>
											4leg#
											{data?.donation_details?.donation_id
												? data?.donation_details?.donation_id
												: "-"}
										</span>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-end",
											width: "50%",
											fontFamily: "Raleway",
											fontSize: "15px",
											fontWeight: "500",
											color: "#001b4c",
										}}
									>
										<span>Date :&nbsp;</span>
										<span>
											{dayjs(data?.donation_details?.donation_date).format(
												"DD/MM/YYYY"
											)}
											{/* {data?.donation_details?.donation_date ? data?.donation_details?.donation_date : "-"} */}
										</span>
									</div>
								</div>

								{/* Date & Receipt No Part */}

								{/* Title Section */}
								<div
									style={{
										fontFamily: "Raleway",
										fontSize: "28px",
										fontWeight: "900",
										color: "#001b4c",
										textAlign: "center",
										// marginTop: "25px",
									}}
								>
									Donation Receipt
								</div>
								{/* Title Section */}

								{/* Details Part */}
								<div
									style={{ marginTop: "30px", height: "58%", padding: "8px" }}
								>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											fontWeight: "500",
											color: "#001b4c",
											display: "flex",
										}}
									>
										<div
											style={{
												fontSize: "16px",
												fontWeight: "500",
												color: "#001b4c",
												width: "12%",
											}}
										>
											Name&nbsp;:&nbsp;
										</div>{" "}
										<div
											style={{
												fontSize: "16px",
												fontWeight: "700",
												color: "#001b4c",
												paddingLeft: "10px",
												// textDecoration: "underline",
												border: "1px solid",
												// display: "block",
												width: "88%",
												textAlign: "left",
											}}
										>
											{data?.donation_details?.donor_first_name}{" "}
											{data?.donation_details?.donor_middle_name}{" "}
											{data?.donation_details?.donor_last_name}
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											fontWeight: "500",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div
											style={{
												fontSize: "16px",
												fontWeight: "500",
												color: "#001b4c",
												width: "12%",
												textAlign: "left",
											}}
										>
											Address&nbsp;:&nbsp;
										</div>{" "}
										<div
											style={{
												fontSize: "16px",
												fontWeight: "700",
												color: "#001b4c",
												paddingLeft: "10px",
												// textDecoration: "underline",
												border: "1px solid",
												// display: "block",
												width: "88%",
												textAlign: "left",
											}}
										>
											{data?.donation_details?.donor_address1}&nbsp;,
											{data?.donation_details?.donor_address2}
											&nbsp;,
											{data?.donation_details?.donor_address3}&nbsp;,
											{data?.donation_details?.city}&nbsp;,
											{data?.donation_details?.state}
											&nbsp; -{data?.donation_details?.pincode}
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "50%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "42%",
													textAlign: "left",
												}}
											>
												Mobile Number&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "58%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.donor_mobile_number}
											</div>
										</div>
										<div
											style={{
												width: "50%",
												display: "flex",
												marginLeft: "15px",
											}}
										>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "38%",
													textAlign: "left",
												}}
											>
												PAN Number&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "62%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.donor_pan_card_number}
											</div>
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											fontWeight: "500",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div
											style={{
												fontSize: "16px",
												fontWeight: "500",
												color: "#001b4c",
												width: "12%",
												textAlign: "left",
											}}
										>
											Email Id&nbsp;:&nbsp;
										</div>{" "}
										<div
											style={{
												fontSize: "16px",
												fontWeight: "700",
												color: "#001b4c",
												paddingLeft: "10px",
												// textDecoration: "underline",
												border: "1px solid",
												// display: "block",
												width: "88%",
												textAlign: "left",
											}}
										>
											{data?.donation_details?.donor_email_id}
										</div>
									</div>

									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "50%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "53%",
													textAlign: "left",
												}}
											>
												Particulars Towards&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "47%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.donation_type === 1 &&
													"Donation"}
												{data?.donation_details?.donation_type === 2 &&
													"Rescue Fund"}
												{data?.donation_details?.donation_type === 3 &&
													"Cattle Fee"}
											</div>
										</div>
										<div
											style={{
												width: "50%",
												display: "flex",
												marginLeft: "15px",
												textAlign: "left",
											}}
										>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "21%",
													textAlign: "left",
												}}
											>
												Amount&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "79%",
													textAlign: "left",
												}}
											>
												<span
													style={{
														// fontSize: "20px",
														background: "#001b4c",
														color: "#fff",
														padding: "3px",
														paddingRight: "5px",
														paddingLeft: "5px",
														marginRight: "10px",
														textAlign: "left",
													}}
												>
													₹
												</span>
												{data?.donation_details?.donation_amount}
												/-
											</div>
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "100%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "24%",
													textAlign: "left",
												}}
											>
												Amount In Words&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "76%",
													textAlign: "left",
												}}
											>
												{numWords(`${data?.donation_details?.donation_amount}`)}{" "}
												rupees only /-
											</div>
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "100%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "20%",
													textAlign: "left",
												}}
											>
												Payment Mode&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "80%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.payment_mode === 1 && "Online"}
												{data?.donation_details?.payment_mode === 2 && "Cash"}
												{data?.donation_details?.payment_mode === 3 && "Cheque"}
											</div>
										</div>
									</div>

									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "100%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "20%",
													textAlign: "left",
												}}
											>
												Bank Details&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "80%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.bank_name
													? data?.donation_details?.bank_name
													: "-"}
											</div>
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "100%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "25%",
													textAlign: "left",
												}}
											>
												Transaction Details&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "75%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.bank_transaction_details
													? data?.donation_details?.bank_transaction_details
													: "-"}
											</div>
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "100%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "15%",
													textAlign: "left",
												}}
											>
												Cheque No&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "85%",
													textAlign: "left",
												}}
											>
												{data?.donation_details?.cheque_number
													? data?.donation_details?.cheque_number
													: "-"}{" "}
											</div>
										</div>
									</div>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "16px",
											color: "#001b4c",
											display: "flex",
											marginTop: "15px",
											textAlign: "left",
										}}
									>
										<div style={{ width: "100%", display: "flex" }}>
											{" "}
											<div
												style={{
													fontWeight: "500",
													width: "28%",
													textAlign: "left",
												}}
											>
												Donation Accepted By&nbsp;:&nbsp;
											</div>{" "}
											<div
												style={{
													fontWeight: "700",
													paddingLeft: "10px",
													// textDecoration: "underline",
													border: "1px solid",
													// display: "block",
													width: "72%",
													textAlign: "left",
												}}
											>
												{data?.donation_collected_by?.first_name}&nbsp;
												{data?.donation_collected_by?.last_name}
											</div>
										</div>
									</div>
									<div
										style={{
											textAlign: "center",
											fontFamily: "Raleway",
											fontSize: "10px",
											fontWeight: "600",
											color: "#000",
											// paddingTop: "5px",
											marginTop: "40px",
										}}
									>
										Thank you for your generous gift to 4Leg Rescue Foundation.
										We are thrilled to have your support. Through your donation,
										we have been able to accomplish help stray animals and
										continue working to give better facilities to animals. You
										truly make a difference for us, and we are extremely
										grateful!
									</div>
									<div
										style={{
											textAlign: "right",
											fontFamily: "Raleway",
											fontSize: "24px",
											fontWeight: "600",
											color: "#000",
											// paddingTop: "5px",
											marginTop: "10px",
											marginRight: "15px",
										}}
									>
										4Leg Rescue Foundation.
									</div>
									{/* <div
							style={{
								marginLeft: "65%",
							}}
						>
							<img
								src={sign}
								className="App-logo"
								alt="logo"
								width={200}
								// marginLeft="60%"
								// paddingTop={50}
							/>
						</div> */}
								</div>
								{/* Details Part */}

								<div
									style={{
										// display: "flex",
										// justifyContent: "flex-end",
										background: "#001b4c",
										padding: "16px",
									}}
								>
									<div
										style={{
											fontFamily: "Raleway",
											fontSize: "12px",
											fontWeight: "500",
											color: "#fff",
											display: "flex",
											textAlign: "left",

											// textAlign: "left",
											// marginTop: "25px",
										}}
									>
										<div style={{ width: "50%" }}>
											{" "}
											<div style={{ textAlign: "start" }}>
												Trust Reg No : E/22963/Ahmedabad
											</div>
											<div style={{ textAlign: "start" }}>
												PAN card No : AAATZ2551M
											</div>{" "}
										</div>
										<div style={{ width: "50%" }}>
											<div style={{ textAlign: "end" }}>
												80G : AAATZ2551MF20212
											</div>{" "}
											<div style={{ textAlign: "end" }}>
												12A : AAATZ2551ME20214
											</div>
										</div>
									</div>
									<div
										style={{
											textAlign: "center",
											fontFamily: "Raleway",
											fontSize: "10px",
											fontWeight: "500",
											color: "#fff",
										}}
									>
										Donations made to 4leg Rescue foundation are tax free under
										IT act 80G
									</div>
								</div>
								<div
									style={{
										textAlign: "center",
										fontFamily: "Raleway",
										fontSize: "10px",
										fontWeight: "600",
										color: "#000",
										paddingTop: "5px",
									}}
								>
									**This is computer generated donations slip does not require
									signature
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}

export default VeryfyRecipts;
