import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import useInput from "../../../hooks/useInput";
import axiosInstance from "../../../utils/AxiosHelper";
import "../../../Auth.css";
import { Button, Grid } from "@mui/material";

function AddUser({ open1, setOpen1 }) {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const [isOtpPage, setisOtpPage] = useState(false);
	const [otp, setOtp] = useState(null);
	// const [password, setPassword] = useState(null);
	var password = document.getElementById("password");

	function genPassword() {
		var chars =
			"0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var passwordLength = 12;
		var password = "";
		for (var i = 0; i <= passwordLength; i++) {
			var randomNumber = Math.floor(Math.random() * chars.length);
			password += chars.substring(randomNumber, randomNumber + 1);
		}
		document.getElementById("password").value = password;
	}

	let {
		enteredInput: enteredEmail,
		inputValid: emailInputIsValid,
		inputInvalid: emailInputIsInvalid,
		blurHandler: emailInputBlurHandler,
		changeHandler: emailInputChangeHandler,
		reset: emailInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredPassword,
		inputValid: passwordInputIsValid,
		inputInvalid: passwordInputIsInvalid,
		blurHandler: passwordInputBlurHandler,
		changeHandler: passwordInputChangeHandler,
		reset: passwordInputReset,
	} = useInput("", (value) => value !== "" && value.length >= 8);

	let {
		enteredInput: enteredConfirmPassword,
		inputValid: confirmPasswordInputIsValid,
		inputInvalid: confirmPasswordInputIsInvalid,
		blurHandler: confirmPasswordInputBlurHandler,
		changeHandler: confirmPasswordInputChangeHandler,
		reset: confirmPasswordInputReset,
	} = useInput(
		"",
		(value) => value === (enteredPassword || password) && value.length >= 8
	);

	let {
		enteredInput: enteredName,
		inputValid: nameInputIsValid,
		inputInvalid: nameInputIsInvalid,
		blurHandler: nameInputBlurHandler,
		changeHandler: nameInputChangeHandler,
		reset: nameInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredlastName,
		inputValid: lastNameInputIsValid,
		inputInvalid: lastNameInputIsInvalid,
		blurHandler: lastNameInputBlurHandler,
		changeHandler: lastNameInputChangeHandler,
		reset: lastNameInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredmobileNumber,
		inputValid: mobileNumberInputIsValid,
		inputInvalid: mobileNumberInputIsInvalid,
		blurHandler: mobileNumberInputBlurHandler,
		changeHandler: mobileNumberInputChangeHandler,
		reset: mobileNumberInputReset,
	} = useInput("", (value) => value !== "");

	let {
		enteredInput: enteredAddress,
		inputValid: addressInputIsValid,
		inputInvalid: addressInputIsInvalid,
		blurHandler: addressInputBlurHandler,
		changeHandler: addressInputChangeHandler,
		reset: addressInputReset,
	} = useInput("", (value) => value !== "");

	const signUpHandler = async (e) => {
		e.preventDefault();

		nameInputBlurHandler();
		emailInputBlurHandler();
		lastNameInputBlurHandler();
		mobileNumberInputBlurHandler();
		// passwordInputBlurHandler();
		// confirmPasswordInputBlurHandler();

		if (
			(!enteredName &&
				!enteredEmail &&
				!enteredlastName &&
				!enteredmobileNumber) ||
			!(enteredmobileNumber >= 9)
			// ||
			// !(enteredPassword.length >= 8) ||
			// enteredPassword !== enteredConfirmPassword
		) {
			return;
		}

		const response = await axiosInstance.post("auth/user-registration", {
			first_name: enteredName,
			last_name: enteredlastName,
			email_id: enteredEmail,
			// password: enteredPassword || password,
			profile_image: "",
			address: enteredAddress,
			user_role: 2,
			user_status: 1,
			mobile_number: enteredmobileNumber,
		});

		if (response.data.message === "success") {
			toast.success("User Added Successfully");
			setOpen1(false);
			// setisOtpPage(true);
		}
	};

	const handleChange = (otp) => {
		setOtp(otp);
	};

	return (
		<div style={{ background: "#e5f2ff", padding: "15px" }} sx={{}} id="app">
			<div>
				{/* <header>
					<div className={"header-headings"}>
						<span>
							{!isOtpPage
								? "Create new account"
								: "Enter your verification code"}
						</span>
					</div>
				</header> */}

				<form className="account-form" onSubmit={signUpHandler}>
					<div className={"account-form-fields"}>
						<Grid container spacing={2}>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<input
										id="name"
										name="name"
										type="name"
										placeholder="First Name"
										value={enteredName}
										onChange={nameInputChangeHandler}
										onBlur={nameInputBlurHandler}
									/>
									{nameInputIsInvalid && (
										<p className="error-text">
											Please enter valid Firts Name address!
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<input
										id="lastName"
										lastName="lastName"
										type="lastName"
										placeholder="Last Name"
										value={enteredlastName}
										onChange={lastNameInputChangeHandler}
										onBlur={lastNameInputBlurHandler}
									/>
									{lastNameInputIsInvalid && (
										<p className="error-text">
											Please enter valid last name address!
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<input
										id="email"
										name="email"
										type="email"
										placeholder="E-mail"
										value={enteredEmail}
										onChange={emailInputChangeHandler}
										onBlur={emailInputBlurHandler}
									/>
									{emailInputIsInvalid && (
										<p className="error-text">
											Please enter valid email address!
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<input
										id="mobileNumber"
										name="mobileNumber"
										type="number"
										placeholder="Mobile Number"
										value={enteredmobileNumber}
										onChange={mobileNumberInputChangeHandler}
										onBlur={mobileNumberInputBlurHandler}
									/>
									{mobileNumberInputIsInvalid && (
										<p className="error-text">
											Please enter valid mobile number
										</p>
									)}{" "}
								</div>
							</Grid>
							<Grid item lg={8} md={8} sm={10} xs={10}>
								{/* <div className={"account-form-fields"}>
									<input
										id="password"
										name="password"
										type="text"
										placeholder="Password"
										value={enteredPassword}
										onChange={passwordInputChangeHandler}
										onBlur={passwordInputBlurHandler}
									/>
									{passwordInputIsInvalid && (
										<p className="error-text">
											Please enter atleast 8 character!
										</p>
									)}
								</div> */}
							</Grid>
							<Grid item lg={4} md={4} sm={2} xs={2}>
								<div className={"account-form-fields"}>
									{/* <input
										id="confirmPassword"
										name="confirmPassword"
										type="password"
										placeholder="Confirm password"
										value={enteredConfirmPassword}
										onChange={confirmPasswordInputChangeHandler}
										onBlur={confirmPasswordInputBlurHandler}
									/>
									{confirmPasswordInputIsInvalid && (
										<p className="error-text">Password should be match!</p>
									)} */}
									{/* <Button
										onClick={() => {
											genPassword();
										}}
									>
										generated
									</Button> */}
								</div>
							</Grid>
							<Grid item lg={12} md={6} sm={12} xs={12}>
								<div className={"account-form-fields"}>
									<input
										id="address"
										name="address"
										type="text"
										placeholder="address"
										value={enteredAddress}
										onChange={addressInputChangeHandler}
										onBlur={addressInputBlurHandler}
									/>
									{/* {addressInputIsInvalid && (
										<p className="error-text"> Please enter valid address</p>
									)} */}
								</div>
							</Grid>
						</Grid>
					</div>
					<button className="btn-submit-form" type="submit">
						Add User{" "}
					</button>
				</form>
				{/* <div className="btn-submit-form" style={{ marginTop: "100px" }}>
					<Link to="/login">Already have an account</Link>
				</div> */}
			</div>
		</div>
	);
}

export default AddUser;
