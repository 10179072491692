import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {},
		title: {
			display: true,
			text: "",
		},
	},
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
	labels,
	datasets: [
		{
			label: "Approved",
			data: [40, 45, 58, 60, 106, 80, 90, 30, 60, 20, 11, 22, 33, 44, 22],
			backgroundColor: "rgba(53, 162, 235, 0.5)",
		},
		{
			label: "Rejected",
			data: [20, 15, 18, 20, 66, 50, 40, 30, 60, 20, 11, 22, 33, 44, 22],
			backgroundColor: "rgba(255, 99, 132, 0.5)",
		},
		{
			label: "Pending",
			data: [20, 15, 18, 20, 66, 50, 40, 30, 60, 20, 11, 22, 33, 44, 22],
			backgroundColor: "rgba(255, 200, 132, 0.5)",
		},
	],
};
export default function TotalGrowthBarCharts() {
	return <Bar options={options} data={data} />;
}
