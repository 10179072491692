import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, Tooltip } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import "./layout.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

function Layout({ children }) {
	const theme = useTheme();
	const navigate = useNavigate();
	const authCtx = React.useContext(AuthContext);
	const [open, setOpen] = React.useState(false);

	let links = [
		{ id: 1, label: "Dashboard", link: "dashboard", icon: <DashboardIcon /> },
		{ id: 3, label: "Products", link: "products", icon: <DashboardIcon /> },
	];

	if (authCtx?.currentUser?.user_role === 1) {
		links = [
			{ id: 1, label: "Dashboard", link: "dashboard", icon: <DashboardIcon /> },
			{
				id: 2,
				label: "User-Management",
				link: "userlist",
				icon: <SupervisedUserCircleIcon />,
			},
			{
				id: 3,
				label: "Receipt-Management",
				link: "receipt-list",
				icon: <ReceiptLongIcon />,
			},
			// {
			// 	id: 3,
			// 	label: "Password Change",
			// 	link: "change-password",
			// 	icon: <ReceiptLongIcon />,
			// },
			// { id: 3, label: "Products", link: "products", icon: <DashboardIcon /> },
			// { id: 4, label: "Users", link: "user", icon: <DashboardIcon /> },
		];
	}

	if (authCtx?.currentUser?.user_role === 2) {
		links = [
			{ id: 1, label: "Dashboard", link: "dashboard", icon: <DashboardIcon /> },

			{
				id: 2,
				label: "Receipt-Management",
				link: "receipt-list",
				icon: <ReceiptLongIcon />,
			},
			// { id: 3, label: "Products", link: "products", icon: <DashboardIcon /> },
			// { id: 4, label: "Users", link: "user", icon: <DashboardIcon /> },
		];
	}
	const [linkData, setLinkData] = React.useState(links);
	const [active, setActive] = React.useState("Dashboard");

	const logout = () => {
		authCtx.logout();
	};

	const settings = [
		{
			id: 1,
			label: "Password Change",
			link: `${
				authCtx?.currentUser?.user_role === 2
					? "/member/change-password"
					: "/admin/change-password"
			}`,
		},
		// { id: 2, label: "Account", link: "/account" },
		// { id: 3, label: "Dashboard", link: "/admin/dashboard" },
		{ id: 4, label: "Logout", fn: logout },
	];
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				open={open}
				// style={{
				//   display: "flex",
				//   justifyContent: "space-between",
				//   alignItems: "center",
				// }}
				style={{ boxShadow: "none" }}
			>
				<Toolbar style={{ background: "#003678" }}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: "none" }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Typography variant="h6" noWrap component="div">
							4Leg Rescue
						</Typography>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								{settings.map((setting) => (
									<MenuItem
										key={setting.id}
										onClick={() => {
											if (setting.link) {
												navigate(setting.link);
												handleCloseUserMenu();
											} else if (setting.fn) {
												setting.fn();
												handleCloseUserMenu();
											}
										}}
									>
										<Typography textAlign="center">{setting.label}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className="sidebar"
				style={{ background: "#003678" }}
				open={open}
			>
				<DrawerHeader style={{ background: "#003678" }}>
					<Typography
						style={{ color: "#fff" }}
						variant="h6"
						noWrap
						component="div"
					>
						4Leg Rescue
					</Typography>
					<IconButton style={{ color: "#fff" }} onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider style={{ background: "#003678" }} />
				<List className="uigguggig" style={{ background: "#003678" }}>
					{linkData.map((link, index) => (
						<ListItem key={link.id} disablePadding sx={{ display: "block" }}>
							<NavLink
								to={link.link}
								onClick={() => {
									setActive(link.label);
								}}
								// className={({ isActive, isPending }) =>
								// 	isPending ? "pending" : active ? "active" : ""
								// }
								className={active ? "active1" : ""}
							>
								<ListItemButton
									// className={active === link.label ? "active" : ""}
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										px: 2.5,
									}}
									className={active ? "active1" : "tetsttt"}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											justifyContent: "center",
										}}
										className={active ? "active1" : "tetsttt"}
									>
										{link.icon}
										{/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
									</ListItemIcon>
									<ListItemText
										primary={link.label}
										sx={{ opacity: open ? 1 : 0 }}
										className={active ? "active1" : "tetsttt"}
									>
										{link.label}
									</ListItemText>
								</ListItemButton>
							</NavLink>
						</ListItem>
					))}
				</List>
				{/* <Divider /> */}
			</Drawer>
			<Box component="main" className="main" sx={{ flexGrow: 1, p: 0 }}>
				<DrawerHeader />

				{children}
			</Box>
		</Box>
	);
}

export default Layout;
